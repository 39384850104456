import React from "react"
import { Link, navigate } from "gatsby"
import SEO from "../components/seo"
import { truncateString } from "../helpers/common"
import { CloudinaryMediaSingle } from "../components/CloudinaryMedia"
import Layout from "../components/layout"
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery"
// Material Kit & Material COre
import GridContainer from "../components/Grid/GridContainer"
import GridItem from "../components/Grid/GridItem"
import Card from "../components/Card/Card"
import CardBody from "../components/Card/CardBody"
import Pagination from "../components/Pagination/Pagination"
import { makeStyles } from "@material-ui/core/styles"

const style = {
  mainImage: {
    height: "180px",
    width: "100%",
    display: "block",
  },
  mainImageMobile: {
    height: "auto",
    width: "100%",
    display: "block",
  },
  descriptionBody: {
    height: "180px",
    position: "relative",
  },
  descriptionContent: {
    position: "absolute",
    bottom: "0",
  },
  navItems: {
    marginTop: "15px",
    marginBottom: "15px",
  },
  justifyContentCenter: {
    "& > ul": {
      justifyContent: "center!important",
    },
  },
}

const useStyles = makeStyles(style)

const PaginationDocs = (props) => {
  const { first, last, index, pageCount, pathPrefix } = props.pageContext
  const classes = useStyles()

  const previousUrl = index - 1 === 1 ? "/" : (index - 1).toString()
  const nextUrl = (index + 1).toString()

  const previous = () => {
    return !first
      ? {
          text: "Previous",
          onClick: () => navigate(`/${pathPrefix}/${previousUrl}`),
        }
      : null
  }

  const next = () => {
    return !last
      ? { text: "Next", onClick: () => navigate(`/${pathPrefix}/${nextUrl}`) }
      : null
  }

  const pages = () => {
    let arr = previous() ? [previous()] : []
    for (let i = 0; i < pageCount; i++) {
      const pageNum = i === 0 ? "" : i + 1
      arr.push({
        text: i + 1,
        onClick: () => navigate(`/${pathPrefix}/${pageNum}`),
        active: index === i + 1,
      })
    }
    if (next()) {
      arr.push(next())
    }
    return arr
  }

  return (
    <div className={classes.justifyContentCenter}>
      <Pagination pages={pages()} />
    </div>
  )
}

const IndexPage = ({ pageContext }) => {
  const classes = useStyles()
  const isMobile = useMediaQuery("(max-width:768px)")
  const { group } = pageContext

  return (
    <Layout>
      <SEO title="CCO Blog" />
      <GridContainer spacing={0}>
        <GridItem>
          <h2 style={{ textAlign: "center" }}>CCO Blog</h2>
          <h4 style={{ padding: "0 10px" }}>
            We come across a lot of amazing boats, interesting products and
            everything in between at CCO! Take a look below and catch a glimpse
            into some of our reviews, walkthroughs and other hot topics of
            conversation. Feel free to reach out if you would like us to cover
            something else in particular!
          </h4>
        </GridItem>
        {group.map((node) => {
          return (
            <GridItem md={3} key={node.id}>
              <Link to={node.alias} style={{ width: "100%" }}>
                <Card>
                  <CloudinaryMediaSingle
                    image={node.image}
                    transformations={
                      isMobile
                        ? "q_100,f_auto,w_300,c_scale"
                        : "q_100,f_auto,h_180,w_294,c_fill"
                    }
                    alt={node.title}
                    styles={isMobile ? style.mainImageMobile : style.mainImage}
                    progressive={
                      isMobile
                        ? "w_300,c_scale,e_blur:1000,q_1,f_auto/e_cartoonify"
                        : "h_180,w_294,c_fill,e_blur:1000,q_1,f_auto/e_cartoonify"
                    }
                  />

                  <CardBody className={classes.descriptionBody}>
                    <h4>{node.title}</h4>
                    <p>{truncateString(node.summary, "130")} - Read More</p>
                  </CardBody>
                </Card>
              </Link>
            </GridItem>
          )
        })}
        <GridItem xs={12} className={classes.navItems}>
          <PaginationDocs pageContext={pageContext} />
        </GridItem>
      </GridContainer>
    </Layout>
  )
}
export default IndexPage
